<template>
  <div class="d-flex flex-column h-full">
    <VOverlay
      :opacity="0.7"
      :value="loading"
    >
      <VProgressCircular
        color="#E43A35"
        indeterminate
        :size="80"
        :width="6"
      />
    </VOverlay>
    <p class="mt-7 ml-7 mb-3 page_title">Зоны доставки</p>
    <div class="ml-7 mb-4 d-flex align-end header">
      <CitySelection @city-selected="city = $event" />
      <RestaurantSelection
        append-icon="mdi-chevron-down"
        :city="city"
        class="ml-6"
        input-placeholder="Выберите из списка"
        label="Заведение"
        :restaurant="restaurant"
        @restaurantSelected="restaurant = $event"
      />
      <VSelect
        v-model="selectedAreaMode"
        append-icon="mdi-chevron-down"
        class="ml-6"
        dense
        hide-details
        :items="areaModes"
        label="Тип зоны"
        outlined
        placeholder="Выберите из списка"
      />
      <UploadCsvFile class="ml-6" />
    </div>
    <VForm
      ref="form"
      v-model="valid"
      class="flex-grow-1 d-flex"
      lazy-validation
    >
      <AreasMap
        ref="areasMap"
        :restaurant-location="restaurant ? serializedCoordinate : null"
        :saved-zones="savedZones"
        @changedSelectedAreas="updateNewZones"
        @deleteHexFromSavedZone="deleteHexFromSavedZone($event.hex, $event.zoneOrder, true)"
      />
      <VBtn
        v-show="hasZonesToShow"
        absolute
        class="show-zones-edit-btn elevation-2"
        color="white"
        fab
        :style="`right: ${ showZonesEdit ? '502px' : '18px' }`"
        @click="showZonesEdit = !showZonesEdit"
      >
        <VIcon size="20" v-text="'mdi-menu-open'"/>
      </VBtn>
      <div
        v-if="showZonesEdit && hasZonesToShow"
        class="map-right-extension-container h-full"
      >
        <DeliveryZone
          v-for="(zone, index) in savedZones"
          :key="`saved-zone-${index}`"
          :city="city || restaurant.city"
          :commission="contractCommissionPercent"
          :order="index + 1"
          :zone="zone"
          @delete="deleteSavedZone(index)"
          @isEdited="editionStarted"
        />
        <DeliveryZone
          v-for="(zone, index) in newZones"
          :key="`new-zone-${index}`"
          :city="city || restaurant.city"
          :order="savedZones.length + index + 1"
          :zone="zone"
          @delete="deleteNotSavedZone(zone)"
          @isEdited="editionStarted"
        />
        <div class="d-flex justify-end pt-3 pr-4">
          <VBtn
            v-if="hasZonesToShow"
            class="subtitle-1 br-8 mr-2"
            color="#6D7885"
            height="38"
            text
            @click="showDeleteConfirmation = true"
          >
            Удалить все зоны
          </VBtn>
          <VBtn
            v-if="isEdited"
            class="elevation-0 white--text subtitle-1 br-8"
            color="#E43539"
            height="38"
            width="214"
            @click="saveDeliveryZoneChanges"
          >
            Сохранить изменения
          </VBtn>
          <VBtn
            v-if="newZones.length && !isEdited"
            class="elevation-0 white--text subtitle-1 br-8"
            color="#E43539"
            :disabled="!valid"
            height="38"
            width="214"
            @click="createDeliveryZones"
          >
            Создать зоны доставки
          </VBtn>
          <VBtn
            v-if="!newZones.length && !isEdited"
            class="br-8"
            color="#E43539"
            height="38"
            outlined
            @click="editAllZones"
          >
            <span class="subtitle-1 black--text">Редактировать все зоны</span>
          </VBtn>
        </div>
      </div>
      <DeliveryZoneDeleteConfirmation
        :show="showDeleteConfirmation"
        :zones-to-delete-count="savedZones.length + newZones.length"
        @close="showDeleteConfirmation = false"
        @delete="deleteAllZones"
      />
    </VForm>
    <SnackbarsSelection is-prepend :items="snackbars" />
  </div>
</template>

<script>
import CitySelection from '@/components/CitySelection.vue';
import AreasMap from '@/components/AreasMap.vue';
import RestaurantSelection from '@/components/RestaurantSelection.vue';
import {
  deliveryAreaAddConditions,
  deliveryAreaDeleteConditions,
  fetchDeliveryAreaWithTariffList,
  fetchContracts,
} from '@/api/api';
import { serializeCoordinate } from '@/serializers/coordinateSerializer';
import UploadCsvFile from '@/components/UploadCsvFile.vue';
import DeliveryZone from '@/components/DeliveryZone.vue';
import DeliveryZoneDeleteConfirmation from '@/components/DeliveryZoneDeleteConfirmation.vue';
import SnackbarsSelection from '@/components/reusable/SnackbarsSelection.vue';
import { mapActions } from 'vuex';

export default {
  name: 'DeliveryAreas',
  components: {
    SnackbarsSelection,
    UploadCsvFile,
    RestaurantSelection,
    CitySelection,
    AreasMap,
    DeliveryZone,
    DeliveryZoneDeleteConfirmation,
  },
  data() {
    return {
      city: null,
      restaurant: null,
      newZones: [],
      selectedDeliveryTime: {},
      contractCommissionPercent: 25,
      loading: false,
      areaModes: [
        { value: 'default', text: 'Обычные' },
        { value: 'reduced', text: 'Суженные' },
      ],
      selectedAreaMode: 'default',
      allAreas: {
        default: [],
        reduced: [],
      },
      isEdited: false,
      valid: true,
      showDeleteConfirmation: false,
      snackbars: [],
      showZonesEdit: true,
      deletedSavedHexes: [],
    };
  },
  mounted() {
    this.getDeliveryTariffs();
    this.getDeliveryTimes();
    this.$root.$on('updateRestaurantAreas', () => {
      this.fetchRestaurantAreas();
    });
  },
  computed: {
    savedZones() {
      return this.allAreas[this.selectedAreaMode];
    },
    serializedCoordinate() {
      return serializeCoordinate(this.restaurant.location.coordinate);
    },
    isReducedArea() {
      return this.selectedAreaMode === 'reduced';
    },
    hasZonesToShow() {
      return this.savedZones.length || this.newZones.length;
    },
  },
  methods: {
    ...mapActions(['getDeliveryTariffs', 'getDeliveryTimes']),
    resetAreas() {
      this.$refs.areasMap.clearMap();
      this.newZones = [];
      this.allAreas.default = [];
      this.allAreas.reduced = [];
      this.isEdited = false;
    },
    fetchRestaurantAreas() {
      if (!this.restaurant) {
        return;
      }
      this.loading = true;
      this.resetAreas();
      const restPk = this.restaurant.pk;
      fetchDeliveryAreaWithTariffList(restPk).then((areasWithTariff) => {
        areasWithTariff.forEach((area) => {
          if (area.isReducedArea) {
            // eslint-disable-next-line no-param-reassign
            area.order = this.allAreas.reduced.length + 1;
            this.allAreas.reduced.push(area);
          } else {
            // eslint-disable-next-line no-param-reassign
            area.order = this.allAreas.default.length + 1;
            this.allAreas.default.push(area);
          }
        });
        this.$refs.areasMap.drawSavedZones(this.savedZones);
      }).finally(() => {
        this.loading = false;
      });
    },
    updateNewZones(zones) {
      this.newZones = zones;
      this.showZonesEdit = true;
    },
    async createDeliveryZones() {
      if (this.$refs.form.validate()) {
        for (const zone of this.newZones) {
          // eslint-disable-next-line no-await-in-loop
          await deliveryAreaAddConditions(
            Object.keys(zone.areaKeys),
            this.restaurant.pk,
            zone.deliveryTariff.pk,
            zone.deliveryTime.pk,
            this.isReducedArea,
          );
        }
        await this.fetchRestaurantAreas();
      }
    },
    async saveDeliveryZoneChanges() {
      if (this.$refs.form.validate()) {
        const areasToSave = this.savedZones.concat(this.newZones)
          .filter((zone) => zone.isEdited);
        for (const zone of areasToSave) {
          // eslint-disable-next-line no-await-in-loop
          await deliveryAreaAddConditions(
            Array.isArray(zone.areaKeys) ? zone.areaKeys : Object.keys(zone.areaKeys),
            this.restaurant.pk,
            zone.deliveryTariff.pk,
            zone.deliveryTime.pk,
            this.isReducedArea,
          );
        }
        if (this.deletedSavedHexes.length) {
          await deliveryAreaDeleteConditions(
            this.deletedSavedHexes,
            this.restaurant.pk,
            this.isReducedArea,
          );
        }
        await this.fetchRestaurantAreas();
      }
    },
    editAllZones() {
      let i = 0;
      while (i < this.savedZones.length) {
        this.allAreas[this.selectedAreaMode][i].isEdited = true;
        i += 1;
      }
      this.editionStarted();
    },
    deleteHexFromSavedZone(hex, zoneOrder, saveToDeletedLater = false) {
      const zoneIndex = this.savedZones.findIndex((saved) => saved.order === zoneOrder);
      const hexIndex = this.savedZones[zoneIndex].areaKeys
        .indexOf(hex);
      this.allAreas[this.selectedAreaMode][zoneIndex].areaKeys.splice(hexIndex, 1);
      this.allAreas[this.selectedAreaMode][zoneIndex].isEdited = true;
      this.isEdited = true;
      if (this.savedZones[zoneIndex].areaKeys.length === 0) {
        this.allAreas[this.selectedAreaMode].splice(zoneIndex, 1);
        this.setSnackbar(zoneOrder);
      }
      if (saveToDeletedLater) {
        this.deletedSavedHexes.push(hex);
      }
    },
    deleteNotSavedZone(zone) {
      const overlappingHexes = Object.keys(zone.overlapping);
      if (overlappingHexes.length) {
        this.loading = true;
        deliveryAreaDeleteConditions(
          overlappingHexes,
          this.restaurant.pk,
          this.isReducedArea,
        )
          .then(() => {
            overlappingHexes.forEach((hex) => {
              const zoneOrder = zone.overlapping[hex];
              this.deleteHexFromSavedZone(hex, zoneOrder);
              this.$refs.areasMap.removeSavedDrawnHex(hex);
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
      this.$refs.areasMap.deleteNewZone(zone.key);
      this.setSnackbar(zone.order);
    },
    deleteSavedZone(index) {
      this.loading = true;
      const areaKeys = this.savedZones[index].areaKeys;
      deliveryAreaDeleteConditions(
        areaKeys,
        this.restaurant.pk,
        this.isReducedArea,
      )
        .then(() => {
          areaKeys.forEach(this.$refs.areasMap.removeSavedDrawnHex);
          this.allAreas[this.selectedAreaMode].splice(index, 1);
          this.setSnackbar(index + 1);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteAllZones() {
      this.showDeleteConfirmation = false;
      this.savedZones.forEach(async (area) => {
        await deliveryAreaDeleteConditions(
          area.areaKeys,
          this.restaurant.pk,
          this.isReducedArea,
        );
      });
      this.allAreas[this.selectedAreaMode] = [];
      this.$refs.areasMap.clearMap();
    },
    editionStarted() {
      if (!this.isEdited) {
        this.isEdited = true;
        fetchContracts({ restaurant_id: this.restaurant.pk }).then((data) => {
          if (!data.results.length) {
            return;
          }
          const contracts = data.results
            .sort((a, b) => new Date(b.commissionStartDate) - new Date(a.commissionStartDate));
          this.contractCommissionPercent = contracts[0].percent;
        });
      }
    },
    setSnackbar(zoneNumber) {
      const snackbar = {
        show: true,
        text: `Зона №${zoneNumber} удалена`,
      };
      this.snackbars.push(snackbar);
    },
  },
  watch: {
    restaurant() {
      this.fetchRestaurantAreas();
    },
    city() {
      this.resetAreas();
      this.restaurant = null;
      this.selectedAreaMode = 'default';
    },
    selectedAreaMode() {
      this.$refs.areasMap.clearMap();
      this.newZones = [];
      this.isEdited = false;
      this.$refs.areasMap.drawSavedZones(this.savedZones);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/scss/main.scss';
@import 'src/assets/scss/page.scss';

::v-deep {
  .mdi-chevron-down {
    font-size: 18px;
  }
  .v-select {
    border-radius: 8px;
  }
  .header .v-select {
    width: 264px;
    max-width: 264px;
  }
  .snackbar {
    .v-snack__content {
      padding: 4px 8px;
    }
    .v-snack__wrapper {
      border-radius: 16px;
      min-width: 358px;
    }
  }
}
</style>
